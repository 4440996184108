.progressBarContainer{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #797979c7;
}
.progressBar{
    background-color: #b4f1b0 !important;
    width: 85%;
    height:10px;
    top : 50%;
    left: 50%;
    transform: translateX(-50%);
    position: absolute,
}
.progressBar span{
    background-color: rgb(15, 231, 83);
}