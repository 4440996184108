.rdrDefinedRangesWrapper {
  display: none;
}

.rdrMonth {
  width: 250px; /* You can adjust the width as needed */
  height: 270px;
}

.rdrWeekDays {
  display: none;
}
