:root {
  --main-bg-color: #ecf0f3;
}

html,
body {
  background-color: var(--main-bg-color);
}

.container {
  margin-top: "auto";
  padding: 2rem;
  border-radius: 2.5rem;
  background-color: var(--main-bg-color);
  box-shadow: 13px 13px 20px #cbced1,
              -13px -13px 20px #ffffff;
}

.logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
}

.logo__circle {
  margin: auto;
  width: 6.25rem;
  height: 6.25rem;
  display: flex;
  border-radius: 9999px;
  background-color: #E9D985;
/*  border: 1px solid black; */
  box-shadow: 
    0px 0px 2px #5F5F5F,
    0px 0px 0px 5px #ECF0F3,
    8px 8px 15px #A7AAAF,
    -8px -8px 15px #FFFFFF;
}

.logo__svg {
  margin: auto;
  /* width: calc(6.25rem);
  height: calc(6.25rem); */
  /* opacity: 0.4; */
}
.logo__svg img{
  margin: auto;
  /* width: calc(6.25rem);
  height: calc(6.25rem); */
  /* opacity: 0.4; */
  border-radius: 50%;
}

.form__group {
  width: 18rem;
  margin-bottom: 2rem;
  position: relative;
}

.form__icon {
  position: absolute;
  left: 0;
  height: 100%;
  display: flex;
  width: 3rem;
}

.form__icon svg {
  margin: auto;
  weight: 0.75rem;
  height: 0.75rem;
  opacity: 0.35;
}

.form__control {
  appearance: none;
  border: none;
  background-color: transparent;
  font-size: 0.875rem;
  padding: 1rem;
  padding-left: 2.5rem;
  width: 100%;
  border-radius: 1.5rem;
  box-shadow: 
    inset 8px 8px 8px #CBCED1,
    inset -8px -8px 8px #FFFFFF;
}

.form__control:focus {
  outline: none;
  box-shadow: 
    inset 8px 8px 8px #c5c5c5,
    inset -8px -8px 8px #FFFFFF;
}

.form__control:focus::placeholder {
  color: #d3d3d3;
  letter-spacing: 0.15em;
  /* display: none; */
}

.form__control::placeholder {
  color: #CCCCCC;
}

.form__button {
  text-transform: uppercase;
  letter-spacing: 0.15em;
  border: none;
  font-size: 0.875rem;
  color: #FFFFFF;
  background-color: black;
  width: 100%;
  display: block;
  padding: 0.875rem 1rem;
  border-radius: 1.5rem;
  box-shadow: 
    3px 3px 8px #B1B1B1,
    -3px -3px 8px #FFFFFF;
}

.form__button:focus {
  outline: none;
  box-shadow: 
    3px 3px 20px #B1B1B1,
    -3px -3px 20px #FFFFFF;
}

.form__button:hover {
  opacity: 0.85;
}