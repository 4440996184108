/* * {
  font-family: "Century Gothic", sans-serif;
}

body {
  margin: 0;
  font-family: Futura, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #eaeceb;
  font-family: "Inter", sans-serif;
} */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

a {
  text-decoration: none;
  color: black;
}

.flex-row {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
  margin-bottom: 20px;
}

.margin-50 {
  margin-bottom: 50px;
}

.padding-all {
  padding: 20px !important;
}

.container {
  width: 100%;
  padding: 0 20px;
}

ul {
  padding: 0;
  cursor: pointer;
}

.logo {
  width: 150px;
  height: 40px;
  padding: 20px 0 20px 20px;
}

.item-disabled::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.4);
}

.list-none {
  list-style: none;
}

.list-none a {
  color: #676869;
  text-decoration: none;
}

.address {
  margin-right: 20px;
}

.page {
  max-width: 960px;
  width: 90%;
  margin: 0 auto;
}

.load-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.header_container {
  background: #ffffff;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dfe3e8;
  box-shadow: rgba(123, 127, 130, 0.2) 0px 20px 20px;
}

.homebutton {
  color: black;
}

.homebutton-inner {
  color: black;
  padding: 0 20px 0 5px;
}

.avatar {
  width: 130px;
  height: 33px;
  padding-left: 20px;
}

.footer {
  margin-top: 30px;
  padding: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: none;
  height: 500px;
  background-color: #ecd6d1;
  font-family: Futura, sans-serif;
  font-size: 15.2px;
  line-height: 35px;
  text-decoration: none solid rgb(103, 104, 105);
  color: #676869;
  white-space: nowrap;
}

.update-phone {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: 20px;
  gap: 20px;
}

.order-container {
  border: 1px solid rgba(89, 85, 85, 0.25);
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 1;
  /* align-items: center; */
  justify-content: space-between;
  padding: 40px 0 40px 0;
  border-radius: 10px;
  background-color: #fffdfd;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.ticket-issue-container {
  margin-top: 20px;
}

.ticket-container {
  width: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 20px 20px;
  padding: 40px 20px 40px 20px;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.25);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.25);
}

.ticketHistory {
  background: rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.25);
}

.chatSection {
  background: rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.25);
}

h1 {
  font-size: 22px;
  background: -webkit-linear-gradient(rgb(40 37 39), rgb(131 118 131));
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

h3 {
  font-size: 14px;
  background: -webkit-linear-gradient(rgb(3, 0, 3), rgb(73, 19, 75));
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.product-row {
  display: flex;
  justify-content: space-between;
  /* align-items: baseline; */
  border-radius: 10px;
  border: 1px solid rgba(89, 85, 85, 0.25);
  padding: 10px 10px 5px 10px;
  background-color: #fffdfd;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.update-email {
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid rgba(89, 85, 85, 0.25);
  padding: 10px 14px 14px 10px;
  margin-top: 10px;
  background-color: #fffdfd;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.product-details {
  position: relative;
  flex: 1;
  display: flex;
  margin: 15px 0;
  padding: 15px;
  justify-content: space-between;
  align-items: center;
  border: 1px dashed #888;
}

.exchange-reason {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #fffdfd;
  border-radius: 10px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  margin-top: 10px;
}

.reason-selector {
  left: 5px;
  width: 16rem;
}

h1.container {
  font-size: 1.2em;
}

.button-right-floating {
  margin-left: auto !important;
  margin-right: 10px !important;
  display: block !important;
}

.customer-message {
  width: 100vh;
  height: 200px;
  border: 1px solid #cfcdcd;
}

.support-message {
  width: 100vh;
  height: 200px;
  border: 1px solid #cfcdcd;
}

.message {
  width: 100vh;
  padding: 20px;
  background-color: rgb(255, 255, 255);
  height: 100%;
  border: 1px solid #cfcdcd;
}
@keyframes fadeInOut {
  0% {
    opacity: 1;
  }
  45% {
    opacity: 1;
  }
  55% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOutIn {
  0% {
    opacity: 0;
  }
  45% {
    opacity: 0;
  }
  55% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
.loader h1 {
  position: absolute;
  text-align: center;
  font-weight: 800;
  font-size: 30px;
  left: 50%;
  top: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
  animation-name: fadeInOut;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-direction: alternate;
}

.loader img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation-name: fadeInOut;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 1s;
  animation-direction: alternate;
}

@media screen and (max-width: 480px) {
  h1.container {
    font-size: 1.8em;
    margin: 50px 0;
  }
  .product-details {
    flex-direction: column;
  }
}

@media screen and (min-width: 481px) {
  h1.container {
    margin: 250px auto;
    background-color: lightcoral;
    width: fit-content;
    padding: 30px;
    border-radius: 5px;
  }
}

.ticket_details {
  display: flex;
  flex-direction: row;
  gap: 32px;
}

@media screen and (max-width: 500px) {
  .ticketHistory {
    height: 100%;
    gap: 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .ticket_details {
    gap: 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .time_stamp {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .correspondence {
    width: 100%;
  }
}

/* Style the Image Used to Trigger the Modal */
img {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

/* img:hover {
    opacity: 0.7;
  } */

/* The Modal (background) */
#image-viewer {
  display: block;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
}
.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  object-fit: contain;
}
.modal-content {
  animation-name: zoom;
  animation-duration: 0.6s;
}
@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
#image-viewer .close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}
#image-viewer .close:hover,
#image-viewer .close:focus {
  color: red;
  text-decoration: none;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .modal-content {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .main-body-cnt {
    width: 100%;
  }

  .product-row {
    background-color: #fffdfd;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  }

  .update-phone {
    margin-bottom: 5px;
    align-items: center;
  }

  .phone-cnt {
    font-size: 13px;
  }

  .product-row .edit-btn {
    font-size: 10px;
    line-height: 0.8rem;
    width: 25px;
    margin-left: 18px;
    background: #434141;
    color: white;
    text-transform: none;
  }
  .product-row .edit-btn:hover {
    background: #434141;
    color: white;
  }

  .product-row .edit-btn:active {
    background: #434141;
    color: white;
  }

  .update-email {
    background-color: #fffdfd;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  }

  .update-email .edit-email {
    padding-left: 12px;
    width: 95%;
    font-size: 13px;
  }

  .order-container {
    background-color: #fffdfd;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  }

  .page .main-title {
    font-size: 41px;
    color: black;
    text-align: center;
  }

  .page .orderid-txt {
    font-size: 19px;
    text-align: center;
  }

  .exchange-reason {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
    background: #fffdfd;
    border-radius: 10px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    margin-top: 10px;
  }

  .reason-selector {
    width: 16rem;
  }

  .reason-slct {
    padding-left: 6px;
    width: 100%;
  }
}
