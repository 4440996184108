/* CSS for Pages */

.page-title {
  color: #222;
}

.dashboard-card-row {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;
}

.dashboard-card .title {
  margin: 0;
  padding: 10px 20px;
  border-bottom: 0.5rem solid #4ae387;
}

.dashboard-card .body {
  padding: 10px 20px;
}

.redirect-login {
  max-width: 360px;
  text-align: center;
  margin: 40px auto;
  padding: 40px 10px;
}

/* Orders Page CSS */
.table-product-row {
  margin: 40px 0;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid black;
}

.table-product-row img {
  width: 25%;
  display: block;
  max-width: 200px;
}

@keyframes fadeInOut {
  0% {
    opacity: 1;
  }
  45% {
    opacity: 1;
  }
  55% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOutIn {
  0% {
    opacity: 0;
  }
  45% {
    opacity: 0;
  }
  55% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
.loader h1 {
  position: absolute;
  text-align: center;
  font-weight: 800;
  font-size: 30px;
  left: 50%;
  top: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
  animation-name: fadeInOut;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-direction: alternate;
}

.loader img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation-name: fadeInOut;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 1s;
  animation-direction: alternate;
}

@media only screen and (min-width: 480px) {
  .dashboard-card-row {
    flex-direction: row;
  }

  .dashboard-card {
    width: calc(50% - 10px);
  }
}

@media only screen and (min-width: 992px) {
  .dashboard-card {
    width: calc(25% - 15px);
  }
}
